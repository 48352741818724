import React, { useState } from 'react';
import { Chat } from '@material-ui/icons';
import { Button } from './TextingHub.styled';
import SendTextDrawer from '../SendTextDrawer';

const SendTextBtn = () => {
  const [isSendTextDrawerVisible, setSendTextDrawerVisible] = useState(false);
  const handleSendBtnClick = async () => {
    try {
      setSendTextDrawerVisible(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button color={'#5eab00'} onClick={handleSendBtnClick}>
        <Chat
          style={{ fontSize: '17px', marginRight: '7px', marginLeft: '4px' }}
        />
        Send Text
      </Button>
      {isSendTextDrawerVisible && (
        <SendTextDrawer
          onClose={() => setSendTextDrawerVisible(false)}
          isDrawerOpen={isSendTextDrawerVisible}
          onOpen={() => setSendTextDrawerVisible(true)}
        />
      )}
    </>
  );
};

export default SendTextBtn;
