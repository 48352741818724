import styled from 'styled-components';

export const Header = styled.div`
  background-color: #005092;
  color: white;
  padding: 10px;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  min-width: 500px;
  height: 100%;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: auto;
`;

export const Title = styled.div`
  font-size: 16px;
  display: flex;
  gap: 10px;
  color: #007ade;
  font-weight: bold;
`;

export const ChatHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100% - 130px);
  margin: 15px 0 15px 15px;
  padding-right: 30px;
`;
