import React from 'react';
import { Close } from '@material-ui/icons';
import { SwipeableDrawer, IconButton } from '@material-ui/core';
import { WechatOutlined } from '@ant-design/icons';
import {
  Container,
  Header,
  Content,
  Title,
  ChatHistory,
} from './SendTextDrawer.styled';

const SendTextDrawer = ({ onClose, isDrawerOpen, onOpen }) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Container>
        <Header>
          <span>
            <IconButton onClick={onClose}>
              <Close style={{ color: 'white' }} />
            </IconButton>
            New Text Conversation
          </span>
        </Header>
        <Content>
          <Title>
            <WechatOutlined style={{ color: '#007ADE', fontSize: '24px' }} />
            Conversation with...
          </Title>
          <ChatHistory />
        </Content>
      </Container>
    </SwipeableDrawer>
  );
};

export default SendTextDrawer;
